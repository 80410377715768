import 'i18next';

import common from '@/locales/de/common.json';
import footer from '@/locales/de/footer.json';
import forms from '@/locales/de/forms.json';

import { DEFlag } from '@/components/icons/countries/DEFlag';
import { FRFlag } from '@/components/icons/countries/FRFlag';

const locales = ['de', 'ch-fr', 'en'];

export type i18nextLocale = typeof locales[number];
export const localesOptions: { code: i18nextLocale; name: string; basePath: string; icon: JSX.Element }[] = [
  {
    code: 'de',
    basePath: '',
    name: 'Deutsch',
    icon: (
      <DEFlag
        width="20px"
        height="15px"
      />
    ),
  },
  {
    code: 'ch-fr',
    basePath: 'ch-fr',
    name: 'Français',
    icon: (
      <FRFlag
        width="20px"
        height="15px"
      />
    ),
  },
];

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: 'common';
    resources: {
      common: typeof common;
      footer: typeof footer;
      forms: typeof forms;
    };
  }
}
