// @ts-check

const stores = /** @type {const} */ (['de-de', 'ch-de', 'ch_fr']);

/** @type {Record<typeof stores[number], string>} */
const basePathByStore = {
  'de-de': '',
  'ch-de': '',
  'ch_fr': '/ch-fr',
};

/** @type {typeof stores[number]} */
const storeCode = /** @type {any} */ (process.env.NEXT_PUBLIC_STORE_CODE);

module.exports = {
  stores,
  storeCode,
  basePath: basePathByStore[storeCode],
};
