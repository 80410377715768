import { useCart } from '@/hooks/cart/useCart';
import { useStore } from '@/hooks/store/useStore';
import { QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client';
import { useCustomer } from '../customer/useCustomer';

export function useCartQuery<Q, V extends { cartId: string; init: boolean; [index: string]: unknown }>(
  document: TypedDocumentNode<Q, V>,
  options: QueryHookOptions<Q, Omit<V, 'cartId'>> & {
    hydration?: boolean;
  } = {},
) {
  const { hydration, ...queryOptions } = options;
  const { cartId } = useCart();
  const { customerToken } = useCustomer();
  const { storeView } = useStore();

  queryOptions.variables = { cartId, init: true, ...options?.variables } as V;
  queryOptions.skip = queryOptions?.skip || !cartId;
  queryOptions.ssr = !!hydration;

  if (customerToken) {
    queryOptions.context = {
      headers: {
        Authorization: 'Bearer ' + customerToken,
        store: storeView,
      },
    };
  }

  const result = useQuery(document, queryOptions);

  return {
    ...result,
  };
}
