export const FRFlag = ({ width = '640px', height = '480px' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 480"
      width={width}
      height={height}
    >
      <rect
        width="213.3"
        height="480"
        fill="#0055a4"
      />
      <rect
        x="213.3"
        width="213.3"
        height="480"
        fill="#fff"
      />
      <rect
        x="426.6"
        width="213.3"
        height="480"
        fill="#ef4135"
      />
    </svg>
  );
};
