import React, { useEffect, useState } from 'react';
import {useTranslation} from "next-i18next";

interface TopHeaderAdsProps {
  topHeaderAdsItems?: [] | null;
}

const TopHeaderAds: React.FC<TopHeaderAdsProps> = ({ topHeaderAdsItems }) => {
  const [topHeaderAdsMounted, setTopHeaderAdsMounted] = useState(false);

  useEffect(() => {
    setTopHeaderAdsMounted(true);
  }, []);

  const bannerItem = topHeaderAdsItems?.find(item => item?.is_active);

  if (!bannerItem) return null;

  const {
    text,
    mobile_text,
    block_link,
    url,
    text_color,
    background_color,
    show_timer,
    timer_start_date,
    end_date
  } = bannerItem;

  return (
    <div
      className="top-header-ads w-full py-2 h-14 flex justify-center text-center"
      style={{ backgroundColor: background_color, color: text_color }}
    >
      <div className="top-header-ads-content flex justify-center items-center">
        {block_link ? (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold hover:underline"
          >
            <span className="hidden md:inline">{text}</span>
            <span className="md:hidden">{mobile_text}</span>
          </a>
        ) : (
          <>
            <span className="hidden md:inline font-bold">{text}</span>
            <span className="md:hidden font-bold">{mobile_text}</span>
          </>
        )}
        {topHeaderAdsMounted && show_timer && (
          <CountdownTimer
            timerStartDate={timer_start_date}
            endDate={end_date}
            timerBackgroundColor={background_color}
          />
        )}
      </div>
    </div>
  );

};

const adjustColorBrightness = (color, amount) => {
  if (color === '#000000') {
    return '#808080';
  }

  if (color.startsWith('#')) {
    // Adjust brightness for hex colors
    let usePound = false;

    if (color[0] === "#") {
      color = color.slice(1);
      usePound = true;
    }

    let num = parseInt(color, 16);
    let r = (num >> 16) + amount;
    let b = ((num >> 8) & 0x00ff) + amount;
    let g = (num & 0x0000ff) + amount;

    const newColor = (
      0x1000000 +
      (r < 255 ? (r < 0 ? 0 : r) : 255) * 0x10000 +
      (b < 255 ? (b < 0 ? 0 : b) : 255) * 0x100 +
      (g < 255 ? (g < 0 ? 0 : g) : 255)
    )
      .toString(16)
      .slice(1);

    return (usePound ? "#" : "") + newColor;
  } else if (color.startsWith('rgba')) {
    const rgba = color
      .replace(/^rgba?\(|\s+|\)$/g, '')
      .split(',');

    let r = parseInt(rgba[0]) + amount;
    let g = parseInt(rgba[1]) + amount;
    let b = parseInt(rgba[2]) + amount;
    let a = rgba[3];

    r = Math.min(255, Math.max(0, r));
    g = Math.min(255, Math.max(0, g));
    b = Math.min(255, Math.max(0, b));

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  // Return the original color if format is not supported
  return color;
};

const CountdownTimer = ({ timerStartDate, endDate, timerBackgroundColor }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const isAfterTimerStartDate = new Date() > new Date(timerStartDate);

  const adjustedBackgroundColor = adjustColorBrightness(timerBackgroundColor, -80);
  const { t } = useTranslation('common');

  return (
    <div className="top-header-ads-timer ml-4 text-sm flex space-x-2">
      {isAfterTimerStartDate && Object.keys(timeLeft).length > 0 ? (
        <>
          <div className="flex flex-col items-center bg-gray-800 text-white py-1 px-2 rounded h-12" style={{ backgroundColor: adjustedBackgroundColor }}>
            <span className="text-base font-bold">{String(timeLeft.days).padStart(2, '0')}</span>
            <span className="text-xs">{t('top-header-ads-days')}</span>
          </div>
          <div className="flex flex-col items-center bg-gray-800 text-white py-1 px-2 rounded h-12" style={{ backgroundColor: adjustedBackgroundColor }}>
            <span className="text-base font-bold">{String(timeLeft.hours).padStart(2, '0')}</span>
            <span className="text-xs">{t('top-header-ads-hours')}</span>
          </div>
          <div className="flex flex-col items-center bg-gray-800 text-white py-1 px-2 rounded h-12" style={{ backgroundColor: adjustedBackgroundColor }}>
            <span className="text-base font-bold">{String(timeLeft.minutes).padStart(2, '0')}</span>
            <span className="text-xs">{t('top-header-ads-mins')}</span>
          </div>
          <div className="flex flex-col items-center bg-gray-800 text-white py-1 px-2 rounded h-12" style={{ backgroundColor: adjustedBackgroundColor }}>
            <span className="text-base font-bold">{String(timeLeft.seconds).padStart(2, '0')}</span>
            <span className="text-xs">{t('top-header-ads-secs')}</span>
          </div>
        </>
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default TopHeaderAds;
