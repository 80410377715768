import Container from '@/components/ui/container';
import Divider from '@/components/ui/divider';
import Input from '@/components/ui/input';
import Link from '@/components/ui/link';
import { CategoryListDocument, CategoryListQuery } from '@/graphql/category/CategoryList.gql';
import { withStaticProps } from '@/server/app';
import { serverEnv } from '@/server/env';
import { apolloClient } from '@/utils/apollo';
import { getCategories } from '@/utils/category';
import { getCategoryRoute } from '@/utils/routes';
import { removeDiacritics } from '@/utils/string';
import trim from 'lodash/trim';
import { useTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import { useState } from 'react';

export const brandCategoryUrlKey = 'marken';
export const popularBrandsCategoryUrlKey = 'popular';

interface BrandsPageProps {
  brands: NonNullable<CategoryListQuery['categories']>['items'];
}

export default function BrandsPage(props: BrandsPageProps) {
  const { t } = useTranslation('common');
  const [search, setSearch] = useState('');

  const searchValue = removeDiacritics(trim(search).toLowerCase());

  const brands =
    searchValue.length > 0
      ? props.brands?.filter((brand) => removeDiacritics(brand?.name?.toLowerCase() as string).includes(searchValue))
      : props.brands;

  const brandsByLetter: Record<string, NonNullable<NonNullable<CategoryListQuery['categories']>['items']>[number][]> =
    {};

  brands
    ?.filter((child) => child?.url_key != popularBrandsCategoryUrlKey)
    ?.map((brand) => {
      const letter = brand?.name?.charAt(0).toUpperCase() as string;
      brandsByLetter[letter] = brandsByLetter[letter] ?? [];
      brandsByLetter[letter]?.push(brand);
    });

  return (
    <>
      <DefaultSeo title={'Marken'} />
      <Divider className="mb-0" />
      <Container>
        <div className="py-10">
          <div className="mb-8 flex">
            <h1 className="text-xl font-semibold text-heading lg:text-2xl 2xl:text-3xl">{t('text-brands')}</h1>
            <Input
              name="brand"
              className="ml-8"
              inputClassName="px-0 md:pl-2 lg:pl-3"
              placeholder="Filter..."
              onChange={(input) => setSearch(input.target.value)}
            />
          </div>
          <div className="text-heading xs:columns-2 md:columns-3 lg:columns-4">
            {Object.keys(brandsByLetter).map((key, idx) => (
              <div
                key={idx}
                className="mb-6 inline-block w-full"
              >
                <div className="mb-2 text-lg font-semibold lg:text-xl">{key}</div>
                <div>
                  {brandsByLetter[key]?.map((brand, idx) => (
                    <Link
                      key={idx}
                      className="block py-0.5 text-sm hover:underline lg:text-base"
                      href={getCategoryRoute(brand?.url_key as string)}
                    >
                      {brand?.name}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
}

export const getStaticProps = withStaticProps<BrandsPageProps>(async ({ categories, store }) => {
  const brandCategory = categories?.find((category) => category?.url_key == brandCategoryUrlKey);

  if (!brandCategory) {
    return {
      notFound: true,
    };
  }

  const categoryListQuery = apolloClient.query({
    query: CategoryListDocument,
    variables: {
      rootUid: brandCategory?.uid as string,
    },
    context: {
      headers: {
        store: store.storeView,
      },
    },
    ttl: serverEnv.GRAPHQL_CACHE_DEFAULT_TTL,
  });

  const brands = (await categoryListQuery).data.categories?.items;

  return {
    props: {
      brands: getCategories(brands, true),
    },
  };
});
