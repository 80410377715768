import { localesOptions } from '@/utils/i18next';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';
import CaretRightIcon from '../icons/caret-right';

export default function LanguageSwitcher() {
  const router = useRouter();
  const { asPath, locale } = router;
  const currentSelectedItem = (
    locale ? localesOptions.find((o) => o.code === locale) : localesOptions[0]
  ) as typeof localesOptions[number];

  const [selectedItem, setSelectedItem] = useState(currentSelectedItem);

  function handleItemClick(localeOption: typeof localesOptions[number]) {
    setSelectedItem(localeOption);

    const newPath = localeOption.basePath ? `/${localeOption.basePath}${asPath.startsWith(`/${localeOption.basePath}`) ? '' : asPath}` : asPath;

    window.location.href = newPath;
  }

  if (process.env.NEXT_PUBLIC_STORE_CODE === 'de-de') {
    return null;
  }

  return (
    <Listbox
      value={selectedItem}
      onChange={handleItemClick}
    >
      {({ open }) => (
        <div className="relative z-10 ms-2 lg:ms-0">
          <Listbox.Button className="shadow-md relative w-full cursor-pointer border border-gray-300 bg-white py-4 pe-8 ps-3 text-start text-[13px] font-semibold text-heading sm:text-sm md:text-sm">
            <span className="flex items-center truncate">{selectedItem?.name}</span>
            <span className="pointer-events-none absolute inset-y-0 end-0 flex items-center pe-3">
              <CaretRightIcon
                width="12"
                className={classNames({ '-rotate-90': open, 'rotate-90': !open })}
              />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              static
              className="absolute mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-sm ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {localesOptions?.map((option) => (
                <Listbox.Option
                  key={option.code}
                  className={({ active }) =>
                    `${active ? 'bg-gray-100 text-heading' : 'text-gray-900'}
												relative cursor-pointer select-none px-3 py-2`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <span className="flex items-center">
                      <span className={`${selected ? 'font-semibold' : 'font-normal'} ms-1.5 block truncate`}>
                        {option.name}
                      </span>
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
